import { twMerge } from 'tailwind-merge';

import type { CSSProperties, PropsWithChildren } from 'react';

type HeadingSizes =
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl';

type HeadingLines = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeadingProps extends PropsWithChildren {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  noOfLines?: HeadingLines;
  size?: HeadingSizes;
  style?: CSSProperties;
  id?: string;
}

export const Heading = ({
  as: Element,
  children,
  className,
  size = 'lg',
  noOfLines,
  ...props
}: HeadingProps) => {
  const sizeVariants: { [key in HeadingSizes]: string } = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
  };

  const noOfLinesVariants: { [key in HeadingLines]: string } = {
    1: 'line-clamp-1',
    2: 'line-clamp-2',
    3: 'line-clamp-3',
    4: 'line-clamp-4',
    5: 'line-clamp-5',
    6: 'line-clamp-6',
  };

  return (
    <Element
      className={twMerge(
        `${sizeVariants[size]} ${
          noOfLines ? `${noOfLinesVariants[noOfLines]} text-ellipsis` : ''
        }`,
        className
      )}
      data-testid="aslan-heading"
      {...props}
    >
      {children}
    </Element>
  );
};

export default Heading;
